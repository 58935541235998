import { useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import axiosClient from "../axios-client";
import { useStateContext } from "../contexts/ContentProvider";

export default function Signup() {
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const passwordRef = useRef();
  const passwordConfRef = useRef();
  const {setUser, setToken} = useStateContext();
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();

  const onSignup = (ev) => {
    ev.preventDefault()
    const payload = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      password: passwordRef.current.value,
      password_confirmation: passwordConfRef.current.value,
    }
    axiosClient.post('/signup', payload)
      .then(({data}) => {
        setUser(data.user);
        setToken(data.token);
        navigate("/");
      })
      .catch(err => {
        const response = err.response;
        if (response && response.status === 422) {
          setErrors(response.data.errors);
        }
      })
  }

  const cleanReset = () => {
    setErrors(null)
  }

  return (
    <div className="">
      <form className="form auth-form" onSubmit={onSignup} method="POST">
        <h2 className="mb-2">Ingresa tus información</h2>
        <input className="mb-3 mt-2" ref={nameRef} placeholder="Nombre Completo" onChange={cleanReset}/>
        <input className="mb-3" ref={emailRef} type="email" placeholder="Email" onChange={cleanReset}/>
        <input className="mb-3" ref={phoneRef} type="text" placeholder="Celular (10 números)" maxLength="10" onChange={cleanReset}/>
        <input className="mb-3" ref={passwordRef} type="password" placeholder="Contraseña" />
        <input className="mb-3" ref={passwordConfRef} type="password" placeholder="Confirmar Contraseña" onChange={cleanReset}/>
        {errors && <div className="">
          {Object.keys(errors).map(key => (
            <p className="error" key={key}>{errors[key][0]}</p>
          ))}  
        </div>
        }
        <div className="mb-2 mt-2">
          <button className="w-100 btn btn-primary">Registrar</button>
        </div>
        <p className="">
          ¿Ya tienes una cuenta? <Link className="link" to="/auth/login">Inicia sesión</Link>
        </p>
      </form>
    </div>
  )
}