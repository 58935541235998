import React from 'react';
import {useEffect, useState} from "react";
import axiosClient from "../axios-client.js";

const Coaches = () => {
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCoaches();
  }, [])

  const getCoaches = () => {
    setLoading(true)
    axiosClient.get('/coaches-active')
      .then(({ data }) => {
        setLoading(false)
        setCoaches(data.data)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <div className='container'>
      <div className='mt-4'>
        <p className='calendario-titulo center mt-5'>Conoce al equipo de coaches Psycle</p>
      </div>

      {loading ? (
        <div className='loading-container center'>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
      <div className='packages-container row'>
        {coaches.map((c) => (
          <div className='col-12 col-sm-6 col-lg-3' key={c.id}>
            <div className='package center'>
              <div className='coach-header'>
                {c.image ? (
                  <img className="img-coach" src={require(`../images/coaches/${c.image}`)} alt="Coach Psycle"></img>
                ) : (
                  <img className="img-coach" src={require(`../images/coaches/imagen-no-disponible.png`)} alt="Coach Psycle"></img>
                )}
                
              </div>
              <p className='price'>{c.name}</p>
            </div>
          </div>
        ))}
      </div>
      )}

    </div>
  );
};

export default Coaches;