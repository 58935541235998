import React from 'react';
import {Link, Outlet, useNavigate} from "react-router-dom";
import logo from '../images/logoweb.png';

const AuthLayout = ({children}) => {
  return (
    <div className="auth-layout">

      {/* header */}
      <div className="navigation">
        <div className="items container">
          <Link to="/"><img className="logo" src={logo} /></Link>
        </div>
      </div>

      <Outlet className="main-outlet" />
    </div>
  );
}

export default AuthLayout;