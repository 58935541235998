import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axiosClient from "../axios-client";
import { useStateContext } from "../contexts/ContentProvider";
import swal from 'sweetalert';

export default function NewPassword() {
  const confirmPasswordRef = useRef();
  const passwordRef = useRef();
  const { setUser, setToken } = useStateContext();
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();
  const { email } = useParams();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (ev) => {
    ev.preventDefault();
    setIsSubmitting(true); 
    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    if (!password || !confirmPassword) {
      setErrors("Ambos campos son obligatorios, favor de llenarlos.");
      setIsSubmitting(false);
      return;
    }

    if (password !== confirmPassword) {
      setErrors("Las contraseñas no coinciden, favor de revisar.");
      setIsSubmitting(false);
      return;
    }

    const payload = {
      token,
      email,
      password,
      password_confirmation: confirmPassword,
    };

    axiosClient
      .post("/reset-password", payload)
      .then(({ data }) => {
        console.log(data)
        if(data.status == "passwords.token") {
          setErrors("El token de la liga no coincide, favor de usar la liga que recibio en su correo.");
          return;
        }
        if(data.status == "passwords.user") {
          setErrors("El correo '"+ payload.email +"' no existe en nuestra base de datos, favor de usar la liga que recibio en su correo o vuelva a generar la solicitud.");
          return;
        }
        if (data.status == "passwords.reset") {
          swal({
            title: "Contraseña guardada",
            text: "Ya puedes usar tu nueva contraseña para iniciar sesión",
            icon: "success",
          }).then(() => {
            navigate('/auth/login')
          });
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsSubmitting(false); 
      });;
  };

  const handleInputChange = () => {
    setErrors(''); // Clear errors when input values change
  };

  return (
    <div className="container">
      <form className="form auth-form" onSubmit={onSubmit} method="POST">
        <h2 className="mb-2">Crea tu nueva contraseña</h2>
        <input className="mb-3" ref={passwordRef} type="password" placeholder="Nueva Contraseña" onChange={handleInputChange}/>
        <input className="mb-3 mt-2" ref={confirmPasswordRef} type="password" placeholder="Confirmar contraseña" onChange={handleInputChange} />
        <div className="mb-1">
          <button className="w-100 btn btn-primary" type="submit" disabled={isSubmitting}>Enviar</button>
        </div>
        {errors && (
          <div className="mt-2">
            <p className="error">{errors}</p>
          </div>
        )}
      </form>
    </div>
  );
}