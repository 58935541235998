import local from '../images/local.jpg';
import Iframe from 'react-iframe'

function App() {
  return (
    <div>
      <div className="hero-section hero-container">
        <div className='center'>
          <h2 className='title-sobre'>Welcome to our family!</h2>
          <h2 className='title-sobre '>
            DO<span className='texto-linea'>N'T</span> <span className='texto-linea'>QU</span>IT
          </h2>
        </div>
      </div>

      <div className="info-section">
        <div className="row container m0a">
          <div className="address-info col-md-6 col-sm-12">
            <img className="img-local" src={local} />
            <div >
              <p>Estardo Guajardo 100-Local 9,</p>
              <p>Residencial San Francisco,</p>
              <p>66600 Cd Apodaca, N.L.</p>
            </div>
          </div>

          <div className='col-md-6 col-sm-12'>
            <Iframe url="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Psycle,%20Estardo%20Guarjardo%20800+(Psycle)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="100%"
              height="400px"
              id=""
              className=""
              display="block"
              position="relative"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
