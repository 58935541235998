import React from 'react';

const Contacto = () => {
  // Component logic and rendering here
  return (
    <div>
      Contacto
    </div>
  );
};

export default Contacto;