import { createContext, useContext, useState } from "react";

const StateContext = createContext({
  user: null,
  token: null,
  setUser: () => {},
  setToken: () => {}
});

export const ContextProvider = ({ children }) => {
  const [user, _setUser] = useState({});
  const [token, _setToken] = useState();

  const setToken = (newToken) => {
    _setToken(newToken);
    if (newToken) {
      localStorage.setItem('ACCESS_TOKEN', newToken);
    } else {
      localStorage.removeItem('ACCESS_TOKEN');
    }
  };

  const getToken = () => {
    return localStorage.getItem('ACCESS_TOKEN')
  }

  const getUser = () => {
    return JSON.parse(localStorage.getItem('USER_DATA'))
  }

  const setUser = (newUser) => {
    _setUser(newUser);
    if (newUser) {
      localStorage.setItem('USER_DATA', JSON.stringify(newUser));
    } else {
      localStorage.removeItem('USER_DATA');
    }
  };

  return (
    <StateContext.Provider
      value={{
        user,
        token,
        setUser,
        getUser,
        setToken,
        getToken
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);