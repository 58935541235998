import React from 'react';

const PreguntasFrecuentes = () => {
  return (
    <div className='container'>
      <h2 className='mt-5 mb-5 center'>Preguntas Frecuentes</h2>

      <div className="accordion accordion-preguntas" id="preguntas-frecuentes-accordion">

        {/* Pregunta 1 */}
        <div className="accordion-item">
          <h3 className="accordion-header" id="pregunta-1">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#respuesta-1" aria-expanded="true" aria-controls="respuesta-1">
              ¿Cómo es la dinámica al llegar a mi clase?
            </button>
          </h3>
          <div id="respuesta-1" className="accordion-collapse collapse show" aria-labelledby="pregunta-1" data-bs-parent="#preguntas-frecuentes-accordion">
            <div className="accordion-body">
              <ol>
                <li>Llega al mostrador 10 minutos antes para confirmar tu lugar y prepararte para tu clase.
                  En caso de llegar unos minutos tarde, espera en recepción para que alguien del staff te de acceso a la clase en un momento adecuado; ya adentro, no olvides calentar antes de empezar para evitar alguna lesión.
                </li>
                <li>Deja tus pertenencias en uno de los lockers que tenemos en recepción y prepárate para tu clase.</li>
                <li>Espera a que salga la clase anterior y el staff haga limpieza dentro del estudio. Ellos te dirán en qué momento ingresar para comenzar tu clase.</li>
                <li>Si es tu primera vez practicando esta disciplina, pide ayuda al coach o al staff para acomodar tu bici correctamente o para cualquier duda que tengas sobre tu clase.</li>
                <li>Presta atención a las indicaciones del coach y no dudes en pedir asistencia en cualquier momento de la clase en caso de que la necesites.</li>
                <li>Al final de tu clase no olvides ningún objeto dentro de los lockers y coloca el candado y llave en su lugar.</li>
              </ol>
              <p>*Si llegas muy tarde a tu clase por algún inconveniente y en el siguiente horario del turno hay lugares disponibles, te daremos oportunidad de tomarla (únicamente en el siguiente horario). En caso de que llegues tarde y no puedas tomar tu clase en el siguiente horario, daremos esa clase como gastada.</p>
            </div>
          </div>
        </div>

        {/* Pregunta 2 */}
        <div className="accordion-item">
          <h3 className="accordion-header" id="pregunta-2">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#respuesta-2" aria-expanded="false" aria-controls="respuesta-2">
              ¿Qué necesito para asistir a mi clase?
            </button>
          </h3>
          <div id="respuesta-2" className="accordion-collapse collapse" aria-labelledby="pregunta-2" data-bs-parent="#preguntas-frecuentes-accordion">
            <div className="accordion-body">
              <ul className='lista-preguntas'>
                <li>Recuerda llevar agua, una toalla pequeña para el sudor y unos tenis cómodos para realizar ejercicio o tus tenis con clips para bicicleta.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Pregunta 3 */}
        <div className="accordion-item">
          <h3 className="accordion-header" id="pregunta-3">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#respuesta-3" aria-expanded="false" aria-controls="respuesta-3">
              ¿Cuánto duran las clases?
            </button>
          </h3>
          <div id="respuesta-3" className="accordion-collapse collapse" aria-labelledby="pregunta-3" data-bs-parent="#preguntas-frecuentes-accordion">
            <div className="accordion-body">
              <p>Tienen una duración de 40-45 minutos de entrenamiento, adicional 10 minutos de calentamiento y estiramiento al inicio y al final de tu clase.</p>
            </div>
          </div>
        </div>

        {/* Pregunta 4 */}
        <div className="accordion-item">
          <h3 className="accordion-header" id="pregunta-4">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#respuesta-4" aria-expanded="false" aria-controls="respuesta-4">
              ¿Las clases que no utilice son acumulables?
            </button>
          </h3>
          <div id="respuesta-4" className="accordion-collapse collapse" aria-labelledby="pregunta-4" data-bs-parent="#preguntas-frecuentes-accordion">
            <div className="accordion-body">
              <p>No, si no son usadas en el tiempo que dura tu paquete, las clases restantes se pierden.</p>
            </div>
          </div>
        </div>

        {/* Pregunta 5 */}
        <div className="accordion-item">
          <h3 className="accordion-header" id="pregunta-5">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#respuesta-5" aria-expanded="false" aria-controls="respuesta-5">
              ¿En caso de aún tener clases el último día de la vigencia de mi paquete, aún puedo agendar las clases disponibles?
            </button>
          </h3>
          <div id="respuesta-5" className="accordion-collapse collapse" aria-labelledby="pregunta-5" data-bs-parent="#preguntas-frecuentes-accordion">
            <div className="accordion-body">
              <p>Sí, el último día de vigencia de tu paquete, es el último día que tienes para agendar las clases restantes en caso de no haberlas gastado todas y, únicamente podrás agendar en los horarios ya disponibles en el calendario.</p>
            </div>
          </div>
        </div>

        {/* Pregunta 6 */}
        <div className="accordion-item">
          <h3 className="accordion-header" id="pregunta-6">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#respuesta-6" aria-expanded="false" aria-controls="respuesta-6">
              ¿Puedo festejar alguna fecha especial en Psycle?
            </button>
          </h3>
          <div id="respuesta-6" className="accordion-collapse collapse" aria-labelledby="pregunta-6" data-bs-parent="#preguntas-frecuentes-accordion">
            <div className="accordion-body">
              <p>Sí. Pregunta en recepción o por DM por los paquetes especiales que tenemos para ti, para que puedas disfrutar y compartir con tus amigos esa fecha especial en Psycle.</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default PreguntasFrecuentes;