import { useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import axiosClient from "../axios-client";
import { useStateContext } from "../contexts/ContentProvider";
import swal from 'sweetalert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const emailReset = useRef();
  const {setUser, setToken} = useStateContext();
  const [errors, setErrors] = useState(null);
  const [errorReset, setResetErrors] = useState(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const onSubmit = (ev) => {
    ev.preventDefault()
    const payload = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    }
    axiosClient.post('/login', payload)
      .then(({data}) => {
        setUser(data.user);
        setToken(data.token);
        navigate("/perfil");
      })
      .catch(err => {
        const response = err.response;
        if (response && response.status === 422) {
          console.log(response)
          setErrors(response.data.message);
        }
      })
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => {
    setShow(true);
  }

  const resetPassword = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailReset.current.value)){
      setResetErrors("Ingresa un correo valido por favor.");
    }
    const payload = {
      email: emailReset.current.value
    }
    axiosClient
      .post("/forgot-password", payload)
      .then(({ data }) => {
        swal({
          title: "Has iniciado tu proceso de recuperación",
          text: "Revisa tu correo para continuar el proceso",
          icon: "success",
        }).then(() => {
          navigate('/')
        });
      })
      .catch((err) => {
        console.log(err)
        const response = err.response;
        if (response && response.status === 422) {
          if (response.data.error == "passwords.user") {
            setResetErrors("No se encontro ningun usuario con ese correo.");
          } else {
            setResetErrors("Hubo un problema con la petición, favor de reportar.");
          } 
        }
      });
  }

  const cleanReset = () => {
    setResetErrors("")
  }

  return (
    <div className="container">
      <form className="form auth-form" onSubmit={onSubmit} method="POST">
        <h2 className="mb-2">Inicia sesión</h2>
        <input className="mb-3 mt-2" ref={emailRef} type="email" placeholder="Email" />
        <input className="mb-3" ref={passwordRef} type="password" placeholder="Password" />
        <div className="mb-1">
          <button className="w-100 btn btn-primary">Ingresa</button>
        </div>
        {errors && <div className="">
          <p className="error">{errors}</p>
        </div>
        }
        <p className="mb-1 mt-2">
          ¿No tienes una cuenta? <Link className="link" to="/auth/signup">Regístrate</Link>
        </p>
        <p className="pointer link" onClick={handleShow}>
          Recuperar Contraseña
        </p>
      </form>

      <Modal className="modal fade" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Recuperar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Ingresa tu correo para iniciar el proceso de recuperación de contraseña</p>
          <input className="w-100 mb-3 mt-2" ref={emailReset} type="email" placeholder="Email" onChange={cleanReset}/>
          {errorReset && <div className="">
            <p className="error">{errorReset}</p>
          </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => resetPassword()}>
            Recuperar
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}