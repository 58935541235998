import React from 'react';
import {useEffect, useState, useRef} from "react";
import { useParams } from "react-router-dom";
import axiosClient from "../axios-client.js";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';

const Perfil = () => {
  const [perfil, setPerfil] = useState([]);
  const [packageHistory, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingHist, setLoadingHist] = useState(false);
  const [loadingTokens, setLoadingTokens] = useState(false);
  const [loadingPostToken, setLoaginPostToken] = useState(false);
  const [packageTokens, setPackageTokens] = useState([]);
  const [show, setShow] = useState(false);
  const tokenRef = useRef();
  const [errorReset, setResetErrors] = useState(null);
  const userData = JSON.parse(localStorage.getItem('USER_DATA'));
  const urlParams = new URLSearchParams(window.location.search);
  const newPackage = urlParams.get('nuevoPaquete');
  
  useEffect(() => {
    getPerfil();
    getTokens();
    getHistory();
    if (newPackage) {
      swal({
        title: "Paquete abonado",
        text: "Ya puedes reservar clases en tu horario preferido.",
        icon: "success",
      })
    }
  }, [])

  const getPerfil = () => {
    setLoading(true)
    axiosClient.get('/perfil')
      .then(({ data }) => {
        setLoading(false)
        setPerfil(data)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const getTokens = () => {
    setLoadingTokens(true)
    const user = JSON.parse(localStorage.getItem('USER_DATA'));
    axiosClient.get('/package-tokens/' + user.id)
      .then(({ data }) => {
        setLoadingTokens(false)
        setPackageTokens(data.data)
      })
      .catch(() => {
        setLoadingTokens(false)
      })
  }

  const getHistory = () => {
    setLoadingHist(true)
    const user = JSON.parse(localStorage.getItem('USER_DATA'));
    axiosClient.get('/package-history/user/' + user.id)
      .then(({ data }) => {
        setLoadingHist(false)
        setHistory(data.data)
      })
      .catch(() => {
        setLoadingHist(false);
      })
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => {
    setShow(true);
  }

  const saveToken = (token) => {
    setLoaginPostToken(true);
    if (!token.current.value) {
      setResetErrors("Favor de ingresar un token");
      setLoaginPostToken(false);
      return;
    }
    const user = JSON.parse(localStorage.getItem('USER_DATA'));
    const payload = {
      status: "Pendiente",
      user_id: user.id,
      token: token.current.value
    }
    axiosClient.post('/package-tokens', payload)
      .then(({ data }) => {
        setLoaginPostToken(false);
        swal({
          title: "Token abonado",
          text: "En cuanto sea validado el token, este sera agregado a tu paquete de clases para poder reservar.",
          icon: "success",
        })
        handleClose();
        getTokens();
      })
      .catch(() => {
        setLoaginPostToken(false);
      })
  }

  const cleanReset = () => {
    setResetErrors("");
  }

  return (
    <div className='container'>
      {loading ? (
        <div className='loading-container center'>
          <div className='spinner-border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <div className='datos-container'>
            <p className='semi-bold'>Datos Personales</p>
            <hr></hr>
            <p className='datos-text'>
              <span className='semi-bold m-2'>Nombre:</span>
              {perfil.name}
            </p>
            <p className='datos-text'>
              <span className='semi-bold m-2'>Email:</span>
              {perfil.email}
            </p>
            <p className='datos-text'>
              <span className='semi-bold m-2'>Celular:</span>
              {perfil.phone}
            </p>
          </div>
        </div>
      )}

      {loadingHist ? (
        <div className='loading-container center'>
          <div className='spinner-border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <div className='datos-container'>
          <p className='semi-bold'>Historial de Paquetes</p>
          <hr></hr>
          {!!!packageHistory ? (
            <p>No tienes clases pronto</p>
          ) : (
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Fecha Compra</th>
                    <th>Fecha Expiración</th>
                    <th>Clases Compradas</th>
                    <th>Clases Usadas</th>
                    <th>Clases Disponibles</th>
                    <th>Estatus</th>
                  </tr>
                </thead>
                <tbody>
                  {packageHistory.map((data) => (
                    <tr key={data.id}>
                      <td>{data.transaction_date}</td>
                      <td>{data.expiration_date}</td>
                      <td>{data.total_classes}</td>
                      <td>{data.used_classes}</td>
                      <td>{data.available_classes}</td>
                      <td>{data.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

{/* {loadingTokens ? (
        <div className='loading-container center'>
          <div className='spinner-border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <div className={`datos-container`}>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='semi-bold'>TotalPass tokens del mes</p>
            <Button variant="primary" onClick={() => handleShow()}>
              Agregar
            </Button>
          </div>

          <hr></hr>
          {!!!packageTokens ? (
            <p>No tienes tokens agregados</p>
          ) : (
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Token</th>
                    <th>Estatus</th>
                  </tr>
                </thead>
                <tbody>
                  {packageTokens.map((data) => (
                    <tr key={data.id}>
                      <td>{data.id}</td>
                      <td>{data.token}</td>
                      <td>{data.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )} */}

      <Modal className="modal fade" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Registro de Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Ingresa tu token de Total Pass para ser validado y agregado a tu paquete de classes del mes.</p>
          <input className="w-100 mb-3 mt-2" ref={tokenRef} type="text" placeholder="Token" onChange={cleanReset}/>
          {errorReset && <div className="">
            <p className="error">{errorReset}</p>
          </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => saveToken(tokenRef) } disabled={loadingPostToken}>
            Agregar
          </Button>
          <Button variant="secondary" onClick={handleClose} disabled={loadingPostToken}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Perfil;