import axios from "axios";
import swal from 'sweetalert';

const { REACT_APP_BASE_URL } = process.env;

const axiosClient = axios.create({
  baseURL: `${REACT_APP_BASE_URL}/api`
})

let onLogoutCallback = null;

axiosClient.setOnLogoutCallback = (callback) => {
  onLogoutCallback = callback;
};

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('ACCESS_TOKEN')
  config.headers.Authorization = `Bearer ${token}`
  return config;
})

axiosClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const { response } = error;
  if (response.request.status === 401) {
    swal({
      title: "Lo sentimos, la sesión ha expirado",
      text: "Favor de voler a iniciar sesión (Si estaba finalizando la compra de un paquete cheque su perfil para validar si se cargo el paquete).",
      closeOnClickOutside: false,
      closeOnEsc: false,
      backdrop: true,
      icon: "warning",
    }).then(() => {
      localStorage.removeItem('ACCESS_TOKEN')
      localStorage.removeItem('USER_DATA')
      navigate('/auth/login')
    });
  }
  throw error;
})

export default axiosClient;

const navigate = (path) => {
  window.location.href = path;
}