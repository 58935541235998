import React from 'react';

const TrainSeats = ({ classDetails, handleShow }) => {
  const firstRow = [1, 2, 'Coach', 3, 4];
  const secondRow = [5, 6, 7, 8, 9];

  const renderSeat = (seatNumber) => {
    const seat = classDetails.seats.find(s => s.seat_number === seatNumber);
    if (!seat) return null;

    return (
      <div 
        key={seat.id}
        onClick={() => seat.status !== 1 && handleShow(seat.seat_number)}
        className={`bola-seat 
          ${classDetails.class.type_class === 'Train' ? 'bola-roja' : ''}
          ${seat.status === 1 ? 'ocupado' : ''}`
        }
      >
        <span>{seat.seat_number}</span>
      </div>
    );
  };

  return (
    <>
      <div className='train-seats-container'>
        <div className={`bola-coach responsive ${classDetails.class.type_class === 'Train' ? 'bola-roja' : ''}`}>
          <span className='name-coach'>{classDetails.class.coach_name ? classDetails.class.coach_name : "Coach"}</span>
        </div>
        <div className='row flex-justify-center'>
          {firstRow.map((item, index) => (
            <div className='bola-fila' key={index}>
              {item === 'Coach' ? (
                <div className={`bola-coach desktop ${classDetails.class.type_class === 'Train' ? 'bola-roja' : ''}`}>
                  <span className='name-coach'>{classDetails.class.coach_name ? classDetails.class.coach_name : "Coach"}</span>
                </div>
              ) : (
                renderSeat(item)
              )}
            </div>
          ))}
        </div>
        <div className='row mt-5 flex-justify-center'>
          {secondRow.map((seatNumber, index) => (
            <div className='bola-fila' key={index}>
              {renderSeat(seatNumber)}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TrainSeats;
