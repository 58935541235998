import React from 'react';

const SobrePsycle = () => {

  return (
    <div className='center'>
      <div className="hero-section2 hero-container ">
        <div className=''>
          {/* <h2 className='title-sobre'>Sobre Psycle.</h2> */}
        </div>
      </div>
      <div className='container mt-5'>
        <div className="row mb-5 mt-5">
          <div className=" mb-5">
            <h4 >Indoor cycling</h4>
            <p className='texto-normal'>El indoor cycling es un ejercicio aeróbico que consiste en trabajar fuerza, resistencia y velocidad pedaleando al ritmo de la música.</p>
          </div>
        </div>
        
        <div className='proceso-reserva'>
          <h3 className='mb-4'>Proceso de reserva:</h3>
          <ul className='sobre-lista'>
            <li>1- Crea una cuenta e inicia sesión.</li>
            <li>2- Ve a la sección de "Comprar clases", elige el paquete de tu preferencia y selecciona tu forma de pago para realizar la compra.</li>
            <li>3- Dirígete a la sección de "Reservar clases" y elige la clase que deseas en el día y hora de tu preferencia.</li>
            <li>4- Selecciona la bicicleta de tu elección, haz clic en "Reservar" y ¡listo!</li>
          </ul>
          
          <p>*Tus clases disponibles y las clases ya agendadas aparecerán en la sección "Mis clases".</p>
          <p>*Recuerda que puedes cancelar una clase con hasta 5 horas de anticipación, lo cual permitirá que otra persona pueda reservar ese espacio.</p>
        </div>
      </div>
    </div>
  );
};

export default SobrePsycle;