import React, { useEffect, useState, useRef } from 'react';
import axiosClient from '../axios-client.js';
import { useLocation, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const { REACT_APP_CONEKTA_KEY } = process.env;

const CheckoutPackage = () => {
  const location = useLocation();
  const { package: selectedPackage } = location.state;
  const [ userID, setUser] = useState(0);
  const [loading, setLoading] = useState(false);
  const [checkoutId, setCheckoutId] = useState('');
  const integrationRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    createConektaOrder();
  }, []);

  const createConektaOrder = () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('USER_DATA'));
    setUser(user.id);
    const payload = {
      package: selectedPackage,
      userID: user.id
    };

    axiosClient
      .post('/conekta-order', payload)
      .then(({ data }) => {
        setCheckoutId(data.order.checkout.id);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (checkoutId) {
      if (integrationRef.current) {
        // Destroy the previous integration instance
        integrationRef.current.destroy();
      }

      initializeConektaIntegration();
    }
  }, [checkoutId]);

  const initializeConektaIntegration = () => {
    const iframeContainer = document.querySelector('#conektaIframeContainer');
    iframeContainer.innerHTML = '';
    integrationRef.current = window.ConektaCheckoutComponents.Integration({
      targetIFrame: '#conektaIframeContainer',
      checkoutRequestId: checkoutId,
      publicKey: REACT_APP_CONEKTA_KEY,
      options: {},
      styles: {},
      onFinalizePayment: function (event) {
        console.log(event);
        const payload = {
          package_id: selectedPackage.id,
          user_id: userID,
          oder_id: event.id
        };
    
        axiosClient
          .post('/package-history', payload)
          .then(({ data }) => {
            swal({
              title: "Paquete abonado",
              text: "Ya puedes reservar clases en tu horario preferido",
              icon: "success",
            }).then(() => {
              navigate('/reservar-clases')
            });
          })
          .catch((e) => {
            swal({
              title: "Error al abonar clases",
              text: "Una disculpa, favor de reportar el error para corregirlo",
              icon: "error",
            });
          });
      }
    });
  };

  const addCommasToNumber = (numberString) => {
    const parts = numberString.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  return (
    <div className='container'>
      {loading ? (
        <div className='loading-container center'>
          <div className='spinner-border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <div className='row'>
          <div className='col-12 col-md-4 mt-5 mb-5 detalle-compra'>
            <p className='checkout-text'>
              <h4>Detalle de Compra</h4>

              <span className='semi-bold me-2'>Vigencia:</span>
              {selectedPackage.expiration_days} días
            </p>

            <p className='checkout-text'>
              <span className='semi-bold me-2'>Numero de Clases:</span>
              {selectedPackage.num_classes}
            </p>

            <p className='checkout-text'>
              <span className='semi-bold me-2'>Precio:</span>
              ${addCommasToNumber(selectedPackage.price)}
            </p>
          </div>
          <div className='col-12 col-md-8' id='conektaIframeContainer' style={{ height: '700px' }} />
        </div>
      )}
    </div>
  );
};

export default CheckoutPackage;