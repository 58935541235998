import { Children } from "react";
import {createBrowserRouter} from "react-router-dom";
import App from "./components/App";
import DefaultLayout from "./components/DefaultLayout";
import AuthLayout from "./components/AuthLayout";
import Login from "./views/Login";
import NotFound from "./views/NotFound";
import Signup from "./views/Signup";
import Users from "./views/Users";
import UserForm from "./views/UserForm";
import ComprarClases from "./views/ComprarClases";
import ReservarClases from "./views/ReservarClases";
import DetallesClase from "./views/DetallesClase";
import Contacto from "./views/Contacto";
import MisClases from "./views/MisClases";
import Perfil from "./views/Perfil";
import Coaches from "./views/Coaches";
import SobrePsycle from "./views/SobrePsycle";
import PreguntasFrecuentes from "./views/PreguntasFrecuentes";
import NewPassword from "./views/NuevaContra";
import CheckoutPackage from "./views/CheckoutPackage";
import AdminPanel from "./views/AdminPanel";

const router = createBrowserRouter([
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      {
        path: '/',
        element: <App />
      },
      {
        path: '/sobre-psycle',
        element: <SobrePsycle />
      },
      {
        path: '/users',
        element: <Users />
      },
      {
        path: '/comprar-clases',
        element: <ComprarClases />
      },
      {
        path: '/comprar-clases/checkout',
        element: <CheckoutPackage />
      },
      {
        path: '/coaches',
        element: <Coaches />
      },
      {
        path: '/preguntas-frecuentes',
        element: <PreguntasFrecuentes />
      },
      {
        path: '/reservar-clases',
        element: <ReservarClases />
      },
      {
        path: '/reservar-clase/:id',
        element: <DetallesClase />
      },
      {
        path: '/mis-clases',
        element: <MisClases />
      },
      {
        path: '/contacto',
        element: <Contacto />
      },
      {
        path: '/perfil',
        element: <Perfil />
      },
      {
        path: '/users/:id',
        element: <UserForm key="userUpdate" />
      },
      {
        path: '/admin',
        element: <AdminPanel />
      },
      {
        path: '*',
        element: <NotFound />
      },
    ]
   },
   {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: '/auth/login',
        element: <Login />
      },
      {
        path: '/auth/signup',
        element: <Signup />
      },
      {
        path: '/auth/nueva-contraseña/:email',
        element: <NewPassword  />
      } 
    ]
   }
])

export default router;