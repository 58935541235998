import React from 'react';
import {useEffect, useState} from "react";
import axiosClient from "../axios-client.js";
import swal from 'sweetalert';
import LoadingSpinner from '../components/LoadingSpinner';
import {useStateContext} from "../contexts/ContentProvider";
import {Link, Outlet, useNavigate} from "react-router-dom";

const ComprarClases = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clickEnabled, setClickEnabled] = useState(true);
  const {setUser, getUser, setToken, getToken} = useStateContext();
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    getPackages();
  }, []);

  const getPackages = () => {
    setLoading(true)
    axiosClient.get(token? '/packages-active': '/packages-guest')
      .then(({ data }) => {
        setLoading(false)
        setPackages(data.data)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const addCommasToNumber = (numberString) => {
    const parts = numberString.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  const createCheckout = (package_data) => {
    if (!package_data.payment_link || !clickEnabled) return;

    if(!token) {
      swal({
        title: "Sesión / Registro",
        text: "Favor de voler a iniciar sesión o registrate para poder comprar paquetes.",
        closeOnClickOutside: false,
        closeOnEsc: false,
        backdrop: true,
        icon: "warning",
      }).then(() => {
        localStorage.removeItem('ACCESS_TOKEN')
        localStorage.removeItem('USER_DATA')
        navigate('/auth/login')
      });
      return;
    }
  
    // Deshabilitar los clics mientras se procesa la solicitud
    setClickEnabled(false);
  
    axiosClient.post('/checkout', { 
      id: package_data.id,
      product_id: package_data.payment_link
    })
    .then(({data}) => {
      window.location.href = data.url;
    })
    .catch(err => {
      swal({
        title: "Error con el sistema de pagos",
        text: "Una disculpa, favor de contactar al personal para comprar directamente en lo que arreglamos el error",
        icon: "error",
      });
    })
    .finally(() => {
      // Habilitar los clics nuevamente después de que se complete la solicitud (éxito o error)
      setClickEnabled(true);
    });
  }

  return (
    <div className='container'>
      <p className='package-intro center'>Compra el paquete de clases de indoor cycling que más te convenga, ahorra y ¡ejercítate!</p>
      <p className='package-comprar center'>COMPRA TUS CLASES</p>
      <p className='center mb-4'>Contamos con <b>TotalPass</b>.</p>
      {loading ? (
        <div className='loading-container center'>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className='packages-container row'>
          {packages.length === 0 ? (
            <p className='text-center'>De momento no tenemos los paquetes listos, favor de intentarlo en otro momento.</p>
          ) : (
            packages.map((p) => (
              <div className={`col-6 col-lg-4`} key={p.id}>
                <div className={`package package-width center ${clickEnabled ? 'pointer' : 'disabled'}`} onClick={() => createCheckout(p)}>
                  <div className='package-header'>
                    <p className='num-clases'>{p.num_classes}</p>
                    <p className='name'>{p.name}</p>
                  </div>
                  <hr className='separator' />
                  <div className='package-details'>
                    <p className='price'>${addCommasToNumber(p.price)}</p>
                    <p className='expiration'>Expira en {p.expiration_days} días</p>
                  </div>
                </div>
              </div>
            ))
          )}
          {!clickEnabled && <LoadingSpinner />}
        </div>
      )}

    </div>
  );
};

export default ComprarClases;