import React, { useEffect, useState } from 'react';
import axiosClient from "../axios-client.js";
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

const ReservarClases = () => {
  const [eventClasses, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getClasses();
  }, []);

  const getClasses = () => {
    setLoading(true);
    axiosClient.get('/classes/calendar/week')
      .then(({ data }) => {
        setLoading(false);
        const groupedData = _.groupBy(data, 'class_date');
        const dataArray = _.map(groupedData, (value, key) => {
          const currentDate = moment();
  
          const classesWithAvailability = value.map((classItem) => {
            const isAvailable = moment(key).isAfter(currentDate, 'day') || (
              moment(key).isSame(currentDate, 'day') &&
              moment(currentDate, 'HH:mm') < moment(classItem.class_time, 'HH:mm')
            );
  
            return {
              ...classItem,
              isAvailable: isAvailable
            };
          });
  
          return {
            date: key,
            classes: classesWithAvailability
          };
        });
  
        setClasses(dataArray);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className='center'>
      <div className='calendario-header'>
        <p className='calendario-titulo'>Calendario</p>
        <p className='calendario-texto'>Elige el día, horario e instructor de tu preferencia.</p>
        <p className='calendario-texto'>*Recuerda que cancelaciones deben realizarse hasta con 5 horas de anticipación, para que la clase regrese a tu perfil y puedas utilizarla en otra ocasión. </p>
      </div>
      <div className='calendario'>
        {loading ? (
          <div className='loading-container center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : eventClasses.length === 0 ? (
          <p className='text-center'>Aun estamos creando el nuevo calendario, favor de intentarlo en otro momento</p>
        ) : (
          <div className='calendario-clase row'>
            {eventClasses.map((d) => (
              <div key={d.date} className='dia-clase'>
                <div className='contenedor-dia'>
                  <div>
                    <p className='numero-dia'>{moment(d.date).date()}</p>
                    <p className='nombre-dia'>{moment(d.date).format('dddd')}</p>
                  </div>
                </div>

                {d.classes.map((c, index) => (
                  /* eslint-disable-next-line */
                  <Link to={`/reservar-clase/${c.id}`} key={index} 
                    className={`contenedor-clase ${!c.isAvailable ? 'unavailable' : ''} ${(c.type_class === 'Ride' || c.type_class === 'Indoor Cycling') ? 'ride' : 'train'}`}>
                    <p className='coach-text mb-2'>{c.type_class}</p>
                    <p className='coach-text'>{c.coach_name}</p>
                    <p className='time-text'>{c.class_time}</p>
                    {c.special_class && <p className='special-text'>{c.special_class}</p>}

                    {!c.special_class && (
                      <p className={`special-text visually-hidden`}>hidden</p>
                    )}
                  </Link>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReservarClases;